import React from 'react';
import {PaymentStepDataHook} from '../PaymentStep';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';

export const PaymentCollapsed = () => {
  const {
    checkoutStore: {checkout},
  } = useControllerProps();

  return (
    <div data-hook={PaymentStepDataHook.collapsed}>
      <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
    </div>
  );
};
