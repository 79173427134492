import {Step} from '../../StepsManager/Components/Step';
import {PaymentOpen} from './Components/PaymentOpen';
import {PaymentCollapsed} from './Components/PaymentCollapsed';
import {PaymentEmpty} from './Components/PaymentEmpty';
import React from 'react';
import {stepImplementationProps, StepTag} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
  continueButton = 'PaymentStep.continue',
  editButton = 'PaymentStep.editButton',
  divider = 'PaymentStep.divider',
}

export const PaymentStep = ({index, isLastStep}: stepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      isLastStep={isLastStep!}
      title={localeKeys.checkout.payment()}
      stepTag={StepTag.payment}
      dataHooks={PaymentStepDataHook}
      openComponent={PaymentOpen}
      collapsedComponent={PaymentCollapsed}
      emptyComponent={PaymentEmpty}
    />
  );
};
