import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {stepImplementationProps, StepTag} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  header = 'CustomerDetailsStep.header',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
  edit = 'CustomerDetailsStep.edit',
  continueButton = 'CustomerDetailsStep.continue',
  editButton = 'CustomerDetailsStep.editButton',
  divider = 'CustomerDetailsStep.divider',
}

export const CustomerDetailsStep = ({index, isLastStep}: stepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      isLastStep={isLastStep!}
      title={localeKeys.checkout.customer_details()}
      stepTag={StepTag.customerDetails}
      dataHooks={CustomerDetailsStepDataHook}
      openComponent={CustomerDetailsOpen}
      collapsedComponent={CustomerDetailsCollapsed}
    />
  );
};
