import React, {Children, ReactElement} from 'react';
import {StepsManagerProps} from '../types';

export enum StepsManagerDataHook {
  root = 'StepsManager.root',
}

export const StepsManager = ({children}: StepsManagerProps) => {
  const actualChildren = children.filter((child) => !!child) as React.ReactElement[];

  return (
    <div data-hook={StepsManagerDataHook.root}>
      {Children.map(actualChildren, (child: ReactElement, index) =>
        React.cloneElement(child, {
          index,
          isLastStep: actualChildren.length === index + 1,
        })
      )}
    </div>
  );
};
