import React from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManager} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {PaymentStep} from '../CheckoutSteps/Steps/PaymentStep/PaymentStep';
import {PlaceOrderStep} from '../CheckoutSteps/Steps/PlaceOrderStep/PlaceOrderStep';

export enum PickupFlowDataHook {
  root = 'PickupFlow.root',
  title = 'PickupFlow.title',
  expressCheckout = 'PickupFlow.expressCheckout',
}

export const PickupFlow = () => {
  const {
    checkoutStore: {
      ambassadorCheckout,
      shouldDisplayExpressCheckout,
      checkout: {payNowTotalAfterGiftCard},
    },
  } = useControllerProps();

  return (
    <div data-hook={PickupFlowDataHook.root}>
      {shouldDisplayExpressCheckout && (
        <div data-hook={PickupFlowDataHook.expressCheckout}>
          <ExpressCheckoutButtons checkout={ambassadorCheckout} layout={'horizontal'} />
        </div>
      )}
      <MemberInfoBox />
      <StepsManager>
        <CustomerDetailsStep />
        {payNowTotalAfterGiftCard.amount ? <PaymentStep /> : null}
        <PlaceOrderStep />
      </StepsManager>
    </div>
  );
};
